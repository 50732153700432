@tailwind base;
@tailwind components;
@tailwind utilities;

@import './vendor/fontawesome/css/fontawesome.css';
@import './vendor/fontawesome/css/duotone.css';
@import './vendor/fontawesome/css/solid.css';

@keyframes hold-button-progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes toast-progress {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.restricted-dev,
.restricted-dev>* {
  background-color: rgba(95, 91, 177, 0.1) !important;
  border-radius: 0 !important;
}

.restricted-doctor,
.restricted-doctor>* {
  background-color: rgba(207, 0, 0, 0.1) !important;
  border-radius: 0 !important;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator,
input[type='datetime-local']::-webkit-inner-spin-button,
input[type='datetime-local']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator,
input[type='month']::-webkit-inner-spin-button,
input[type='month']::-webkit-calendar-picker-indicator,
input[type='week']::-webkit-inner-spin-button,
input[type='week']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.firebase-emulator-warning {
  display: none;
}
.communication-banner .push-communication-banner {
  transform: translateY(-65px);
}
.field-container + .field-container {
  margin-top: 1.5rem;
}

.data-table + .data-table {
  margin-top: 2rem;
}

.data-table table tbody tr:last-child {
  border-bottom: none;
}

.disabled,
*:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

[data-theme='medyx'] .badge.badge-ghost {
  /* base-300 */
  background-color: oklch(var(--b3) / var(--tw-bg-opacity));
  /* primary */
  color: oklch(var(--p) / var(--tw-text-opacity));
}

[data-theme='medyx'] .btn.btn-ghost:disabled {
  background: none;
}

[data-theme='medyx'] .btn.btn-link {
  text-decoration: none;
  color: oklch(var(--bc) / var(--tw-text-opacity));
  opacity: 0.8;
  transition: opacity 200ms ease-in-out;
}

[data-theme='medyx'] .btn.btn-link:hover,
[data-theme='medyx'] .btn.btn-link:focus {
  opacity: 1;
}

[data-theme='medyx'] .btn.btn-primary,
[data-theme='medyx'] .btn.btn-error,
[data-theme='medyx'] .bg-success,
[data-theme='medyx'] .bg-error {
  color: #fff;
}

[data-theme='medyx'] .tabs {
  flex-wrap: nowrap;
  overflow: visible;
  position: relative;
  gap: var(--tab-padding, 1rem);
}

[data-theme='medyx'] .tabs.tabs-bordered::after {
  content: '';
  --tw-border-opacity: 0.2;
  /* neutral */
  border-color: oklch(var(--bc) / var(--tw-border-opacity));
  border-style: solid;
  border-bottom-width: calc(var(--tab-border, 1px) + 1px);
  width: 200vw;
  position: absolute;
  left: -100vw;
}

[data-theme='medyx'] .tabs-bordered .tab {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

[data-theme='medyx'] .tabs-bordered .tab {
  flex-shrink: 0;
  --tw-border-opacity: 0;
}

[data-theme='medyx'] .tabs-bordered .tab.tab-active:not(.tab-disabled):not([disabled]) {
  /* primary */
  border-color: oklch(var(--p) / var(--tw-border-opacity));
  color: oklch(var(--p) / var(--tw-border-opacity));
}

[data-theme='medyx'] .card {
  overflow: hidden;
}

[data-theme='medyx'] .card-actions {
  margin-left: calc(-1 * var(--padding-card));
  margin-right: calc(-1 * var(--padding-card));
  margin-bottom: calc(-1 * var(--padding-card));
  margin-top: var(--padding-card);
  padding: var(--padding-card);
}

[data-theme='medyx'] .menu li.disabled > * {
  --tw-text-opacity: 0.8;
}

.spinner {
  position: relative;
  animation: spinner 2.5s infinite linear both;
}

.spinner-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: spinner-dot 2s infinite ease-in-out both;
}

.spinner-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  /* primary */
  background-color: oklch(var(--p));
  border-radius: 100%;
  animation: spinner-dot-before 2s infinite ease-in-out both;
}

.spinner-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.spinner-dot:nth-child(2) {
  animation-delay: -1s;
}
.spinner-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.spinner-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.spinner-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.spinner-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.spinner-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.spinner-dot:nth-child(2):before {
  animation-delay: -1s;
}
.spinner-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.spinner-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.spinner-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.spinner-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

@media screen and (max-width: 640px) {
  .scroll-container-wrapper {
    position: relative;
  }

  .scroll-container-wrapper::before,
  .scroll-container-wrapper::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
  }
  .scroll-container-wrapper::before {
    top: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }

  .scroll-container-wrapper::after {
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }

  .scroll-container {
    max-height: 200px;
    overflow: scroll;
    padding: 20px 0;
  }
}
